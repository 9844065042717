import React from "react";

const experienceContent = [
  {
    year: "2021 - Present",
    position: "Senior Web Developer",
    compnayName: "Suprem Tech",
    details: `Worked on front-end development, app development, UI/UX design.`,
  },
  {
    year: "   2018 - 2021",
    position: "Senior Front-End Developer",
    compnayName: "Info Datalogy Solutions Pvt. Ltd.",
    details: `Worked on UI/UX design, front-end development, app development.`,
  },
  {
    year: "2012 - 2018",
    position: "UI/UX Designer & Developer",
    compnayName: "Ronline IT Solutions",
    details: `Worked on graphics design, brochure design, email template design, web design, app desing, front-end development.`,
  },
  {
    year: "2011 - 2012",
    position: "UI/UX Design",
    compnayName: "WebFoxTech",
    details: `Worked on graphics design, brochure design, email template design, web design, app desing.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
