import ReactGA from 'react-ga';

const Analytics = (props) => {

    ReactGA.initialize('G-63TC003SVK');
    ReactGA.set({ page: props.action });
    ReactGA.pageview(props.action);
    ReactGA.event({
        category: "props.action",
        action: `User visit to ${props.action}`,
    });
    return (<></>);
};

export default Analytics;
