import React from "react";
import Analytics from "./Analytics";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Address Point</span>Pratap Nagar, Nagpur
        440022, Maharashtra India.
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">mail me</span>{" "}
        <a href="mailto:me@sandeepbansod.com">me@sandeepbansod.com</a>
      </p>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">mail me</span>{" "}
        <a href="mailto:sndpbansod5@gmail.com">sndpbansod5@gmail.com</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">call me</span>{" "}
        <a href="Tel: +91 - 7757932477">+91 - 7757932477</a>
      </p>
      {/* End .custom-span-contact */}
      <Analytics action="Address" />
    </>
  );
};

export default Address;
